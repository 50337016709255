
















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { EmailFormValue, PropsEmailForm, useEmailForm } from './EmailFormComposable';

type Props = PropsEmailForm;

export default defineComponent({
  name: 'PlaygroundEmailForm',
  components: { BaseText },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: String as PropType<EmailFormValue>, required: true },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useEmailForm(props, emit);
  },
});
