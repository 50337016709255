







import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import RequestCreatePlaygroundForm from '../components/organisms/RequestCreatePlaygroundForm.vue';

export default defineComponent({
  name: 'PlaygroundRequestPlaygroundPage',
  components: { MyApp, RequestCreatePlaygroundForm },
  props: { email: { type: String, default: undefined } },
});
