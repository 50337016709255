import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed } from '@/base/usecases';
import { useRequestToCreatePlayground } from '@/playground/usecases';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { EmailFormValue } from '../molecules/EmailFormComposable';

export type PropsRequestCreatePlaygroundForm = {
  email?: string;
};

export function useRequestCreatePlaygroundForm(props: PropsRequestCreatePlaygroundForm) {
  const msgs = useMessages({ prefix: 'playground.organisms.requestCreatePlaygroundForm' });

  const route = useRoute();
  const router = useRouter();

  const input = ref<EmailFormValue>('');
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();
  const sent = ref<boolean>();
  const isMessageNotSentOpen = ref(false);

  function init() {
    if (!route.query.email) return;
    if (typeof route.query.email === 'string') input.value = route.query.email;
    router.replace({ name: 'requestCreatePlayground' });
  }
  onMounted(init);
  watch(() => props.email, init);

  const requestCreatePlayground = useRequestToCreatePlayground();
  async function submit(email: EmailFormValue) {
    errors.value = undefined;
    loading.value = true;
    const res = await requestCreatePlayground.execute({ email });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      sent.value = true;
    });
  }

  function resend() {
    router.replace({
      name: 'requestCreatePlayground',
      query: { email: input.value },
    });
    sent.value = false;
  }

  const isOnlyOneHistory = window.history.length === 1;

  const messageSent = computed(() =>
    isOnlyOneHistory ? msgs.of('message').value : msgs.of('messageCloseTab').value
  );

  function close() {
    window.close();
  }

  return {
    input,
    loading,
    errors,
    sent,
    isMessageNotSentOpen,
    isOnlyOneHistory,
    messageSent,
    labelSend: msgs.of('send'),
    description: msgs.of('description'),
    labelRecipient: msgs.of('recipient'),
    labelNotSent: msgs.of('notSent'),
    messageNotSent: msgs.of('messageNotSent'),
    labelClose: msgs.of('close'),
    labelResend: msgs.of('resend'),
    submit,
    close,
    resend,
  };
}
