

















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsUserCodeForm, UserCodeFormValue, useUserCodeForm } from './UserCodeFormComposable';

type Props = PropsUserCodeForm;

export default defineComponent({
  name: 'PlaygroundUserCodeForm',
  components: { BaseText },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: String as PropType<UserCodeFormValue>, required: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserCodeForm(props, emit);
  },
});
