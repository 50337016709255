









































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import MenuWrapper from '@/base/app/components/atoms/MenuWrapper.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import EmailForm from '../molecules/EmailForm.vue';
import {
  PropsRequestCreatePlaygroundForm,
  useRequestCreatePlaygroundForm,
} from './RequestCreatePlaygroundFormComposable';

type Props = PropsRequestCreatePlaygroundForm;
export default defineComponent({
  name: 'PlaygroundRequestCreatePlaygroundForm',
  components: {
    LogoButton,
    BaseMarkdown,
    BaseButton,
    ErrorMessages,
    EmailForm,
    MenuWrapper,
  },
  props: { email: { type: String, default: undefined } },
  setup(props: Props) {
    return useRequestCreatePlaygroundForm(props);
  },
});
