







import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import CreatePlayground from '../components/organisms/CreatePlayground.vue';

export default defineComponent({
  name: 'PlaygroundCreatePlaygroundPage',
  components: { MyApp, CreatePlayground },
  props: { id: { type: String, required: true } },
});
