





























































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';
import NotFound from '@/base/app/components/organisms/NotFound.vue';

import CompletedToCreatePlayground from '../molecules/CompletedToCreatePlayground.vue';
import UserCodeForm from '../molecules/UserCodeForm.vue';
import { PropsCreatePlayground, useCreatePlayground } from './CreatePlaygroundComposable';
import PasswordDialog from './PasswordDialog.vue';

type Props = PropsCreatePlayground;
export default defineComponent({
  name: 'PlaygroundCreatePlayground',
  components: {
    LogoButton,
    Loading,
    BaseMarkdown,
    BaseButton,
    ErrorMessages,
    NotFound,
    CompletedToCreatePlayground,
    UserCodeForm,
    PasswordDialog,
    HintIcon,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props: Props) {
    return useCreatePlayground(props);
  },
});
