










import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';

import { useCompletedToCreatePlayground } from './CompletedToCreatePlaygroundComposable';

export default defineComponent({
  name: 'PlaygroundCompletedToCreatePlayground',
  components: {
    BaseMarkdown,
    BaseButton,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['sign-in'],
  setup(_, { emit }: SetupContext) {
    return useCompletedToCreatePlayground(emit);
  },
});
