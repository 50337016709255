






















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import PasswordForm from '../molecules/PasswordForm.vue';
import { usePasswordDialog } from './PasswordDialogComposable';

export default defineComponent({
  name: 'PlaygroundPasswordDialog',
  components: { BaseMarkdown, BaseButton, PasswordForm, ErrorMessages },
  inheritAttrs: false,
  emits: ['submit'],
  setup(_, { emit }: SetupContext) {
    return usePasswordDialog(emit);
  },
});
