import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type EmailFormValue = string;

export type PropsEmailForm = {
  value: EmailFormValue;
};

export function useEmailForm(
  props: PropsEmailForm,
  emit: (name: string, arg: EmailFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref();

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', input.value);
  }

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'playground.molecules.emailForm' });

  return { observer, input, labelMailAddress: msgs.of('mailAddress'), submit };
}
