import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type UserCodeFormValue = string;

export type PropsUserCodeForm = {
  value: UserCodeFormValue;
};

export function useUserCodeForm(
  props: PropsUserCodeForm,
  emit: (name: string, arg: UserCodeFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref();

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', input.value);
  }

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'playground.molecules.userCodeForm' });
  return { observer, input, labelUserCode: msgs.of('userCode'), submit };
}
