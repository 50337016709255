import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseMarkdownClickAnchorPayload } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { Subscription } from '@/base/domains';
import { Optional } from '@/base/types';
import { isFailed, isSucceeded } from '@/base/usecases';
import { config } from '@/config';
import {
  PasswordDialog,
  PasswordDialogPayload,
} from '@/playground/app/components/organisms/PasswordDialogComposable';
import { PlaygroundReference } from '@/playground/domains';
import { useGetPlayground, useStartPlaygroundCreating } from '@/playground/usecases';
import { useSubscribePlaygroundCreated } from '@/playground/usecases/SubscribePlaygroundCreated';
import { assertIsDefined } from '@/utils/Asserts';
import { useRouter } from '@/utils/VueUtils';

type PasswordDialogParams = {
  updatePwd: (newPwd: string) => Promise<string>;
};

function usePasswordDialog() {
  const passwordDialog = ref<PasswordDialog>();
  function open(params: PasswordDialogParams) {
    assertIsDefined(passwordDialog.value);
    passwordDialog.value.open(params);
  }
  return { passwordDialog, open };
}

export type PropsCreatePlayground = {
  id: string;
};

export function useCreatePlayground(props: PropsCreatePlayground) {
  const msgs = useMessages({ prefix: 'playground.organisms.createPlayground' });

  const { signIn } = useGlobalStore();
  const { passwordDialog, open: openPassword } = usePasswordDialog();

  const router = useRouter();

  const input = ref<string>('your-name');
  const pageLoading = ref(false);
  const buttonLoading = ref(false);
  const creatingLoading = ref(false);
  const errors = ref<ErrorMessage[]>();
  const playground = ref<PlaygroundReference>();
  const startedCreating = ref(false);

  const getPlayground = useGetPlayground();
  async function fetch() {
    pageLoading.value = true;
    const res = await getPlayground.execute({ id: props.id });
    pageLoading.value = false;
    if (isFailed(res) || !res.playground) {
      playground.value = undefined;
      return;
    }
    playground.value = res.playground;
  }

  let subscription: Optional<Subscription>;
  const subscribePlayground = useSubscribePlaygroundCreated();
  async function subscribe() {
    creatingLoading.value = true;
    const res = await subscribePlayground.execute({
      id: props.id,
      onSucceeded: () => {
        fetch();
        creatingLoading.value = false;
      },
      onFailed: () => {
        playground.value = undefined;
        creatingLoading.value = false;
      },
    });
    if (isSucceeded(res)) {
      subscription = res.subscription;
    } else {
      playground.value = undefined;
      creatingLoading.value = false;
    }
  }

  function unsubscribe() {
    if (!subscription) return;
    subscription.unsubscribe();
    subscription = undefined;
  }
  onUnmounted(unsubscribe);

  async function init() {
    await fetch();
    if (!playground.value) return;
    switch (playground.value.status) {
      case 'used':
        router.push({
          name: 'signIn',
          query: { tenantCode: playground.value.tenantCode, code: playground.value.userCode },
        });
        break;
      case 'creating':
        await subscribe();
        break;
      case 'reserved':
      case 'created':
      default:
    }
  }
  onMounted(init);

  const createPlayground = useStartPlaygroundCreating();
  async function submit() {
    if (playground.value?.status !== 'reserved') return;
    errors.value = undefined;
    buttonLoading.value = true;
    startedCreating.value = true;
    const res = await createPlayground.execute({
      id: props.id,
      userCode: input.value,
      onSucceeded: () => {
        fetch();
        buttonLoading.value = false;
        startedCreating.value = false;
      },
      onFailed: () => {
        buttonLoading.value = false;
        startedCreating.value = false;
        errors.value = [msgs.of('failedToCreate').value];
      },
    });
    if (isSucceeded(res)) {
      subscription = res.subscription;
    } else {
      buttonLoading.value = false;
      startedCreating.value = false;
      errors.value = [msgs.of('failedToCreate').value];
    }
  }

  async function submitCode() {
    if (playground.value?.status !== 'created') return;
    buttonLoading.value = true;
    assertIsDefined(playground.value);
    assertIsDefined(playground.value.userCode);
    assertIsDefined(playground.value.temporaryPassword);
    const { tenantCode, userCode, temporaryPassword } = playground.value;
    const ret = await signIn({
      tenantCode,
      userCode,
      password: temporaryPassword,
    });
    if (typeof ret === 'function') openPassword({ updatePwd: ret });
    buttonLoading.value = false;
  }

  async function changePassword(payload: PasswordDialogPayload<PasswordDialogParams>) {
    try {
      await payload.params.updatePwd(payload.newPwd);
      waitTransition(() => {
        payload.done();
      });
    } catch (e) {
      payload.done([msgs.of('failedChangePassword').value]);
    }
  }

  const notFound = computed(() => !pageLoading.value && !playground.value);

  const description = computed(
    () =>
      msgs.of('description', {
        link: config().app.playgroundTermsOfServiceUrl,
      }).value
  );

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    if (!payload.event.target || !(payload.event.target instanceof Element)) return;
    const e = payload.event.target as Element;
    const href = e.getAttribute('href');
    if (!href) return;
    window.open(href, '_blank');
  }

  return {
    notFound,
    passwordDialog,
    input,
    pageLoading,
    buttonLoading,
    creatingLoading,
    errors,
    playground,
    startedCreating,
    description,
    labelDescriptionFunction: msgs.of('descriptionFunction'),
    labelDescriptionCode: msgs.of('descriptionCode'),
    labelChargedOption: msgs.of('chargedOption'),
    labelLimitedFunctions: msgs.of('limitedFunctions'),
    labelCreating: msgs.of('messageCreating'),
    labelCreate: msgs.of('create'),
    caution: msgs.of('caution'),
    messageCreating: msgs.of('messageCreating'),
    labelNewPassword: msgs.of('newPassword'),
    notFoundBody: msgs.of('notFoundBody'),
    submit,
    submitCode,
    changePassword,
    clickAnchor,
  };
}
