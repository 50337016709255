



































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import { PasswordFormValue, PropsPasswordForm, usePasswordForm } from './PasswordFormComposable';

type Props = PropsPasswordForm;

export default defineComponent({
  name: 'PlaygroundPasswordForm',
  components: { BaseText, HintIcon },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<PasswordFormValue>, required: true },
    height: { type: String, default: undefined },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return usePasswordForm(props, emit);
  },
});
